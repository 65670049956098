import pageQuery from '~/graphql/queries/pages/page.gql';
import type {Page} from '~/graphql/graphql';

export default defineNuxtRouteMiddleware(async (route) => {
  const currentPage = useState<Page | null>('currentPage', () => null);

  const slug = route.path.replace(/^\//, '');
  const {data} = await useAsyncQuery<{page: Page}>(pageQuery, {segments: slug === '' ? 'home' : slug});

  const page = data.value?.page;
  if (!page) {
    throw createError({statusCode: 404, statusMessage: 'Pagina niet gevonden'});
  }

  currentPage.value = data.value?.page ?? null;

  useHead({
    title: page.title,
  });
});
